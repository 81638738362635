
import { computed, defineComponent, onMounted, ref } from "vue";
import {
  LayoutFooter,
  SectionColors,
  Back as CommonBack,
  Section as CommonSection,
} from "@/components";
import { useI18n } from "vue-i18n";
import { ActionTypes, AdvertisementSpots } from "@/store";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faDesktop,
  faCartPlus,
  faGift,
  faKey,
  faEuroSign,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faDesktop, faCartPlus, faGift, faKey, faEuroSign);

export default defineComponent({
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    const store = useStore();
    const route = useRoute();

    const pageReady = ref(false);

    const shopItem = computed(() =>
      store.getters.shopItemByID(route.params.id as string)
    );

    onMounted(() =>
      Promise.all([
        store.dispatch(ActionTypes.GetShopItem, route.params.id as string),
      ]).then(() => (pageReady.value = true))
    );

    return {
      t,
      store,
      pageReady,
      shopItem,
    };
  },
  methods: {
    addToCart() {
      this.store.dispatch(ActionTypes.AddToCart, this.shopItem);
    },
  },
  data() {
    return {
      adSpotFooter: AdvertisementSpots.ShopItemFooter,
      mainColor: SectionColors.ShopMainColor,
      secondaryColor: SectionColors.ShopSecondaryColor,
    };
  },
  computed: {
    cssProps(): unknown {
      return {
        "--streams-main-color": this.$data.mainColor,
        "--streams-secondary-color": this.$data.secondaryColor,
      };
    },
  },
  components: {
    CommonBack,
    CommonSection,
    FontAwesomeIcon,
    LayoutFooter,
  },
});

import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";


export function initializeLocalization(): void {
    const router = useRouter();
    const { locale } = useI18n();

    router.beforeResolve((to) => {
        locale.value = to.params.locale as string;
    });
}
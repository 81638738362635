import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-56dbf2ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "image" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "card-header" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "sub-title" }
const _hoisted_6 = { class: "description" }
const _hoisted_7 = { class: "button-row" }
const _hoisted_8 = { class: "image-image" }
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "card-header" }
const _hoisted_11 = { class: "title" }
const _hoisted_12 = { class: "sub-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_common_label = _resolveComponent("common-label")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.type == 'highlighted' && _ctx.stream != undefined)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "stream-card",
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$router.push({ name: 'Stream', params: { id: _ctx.stream.id } })), ["stop"]))
        }, [
          (_ctx.label != undefined)
            ? (_openBlock(), _createBlock(_component_common_label, {
                key: 0,
                colorTop: _ctx.mainColor,
                colorBottom: _ctx.secondaryColor,
                text: _ctx.label,
                class: "label"
              }, null, 8, ["colorTop", "colorBottom", "text"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("img", {
              src: _ctx.stream.thumbnailUri
            }, null, 8, _hoisted_2)
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.stream.title), 1),
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.stream.subTitle), 1),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("p", null, _toDisplayString(_ctx.stream.description), 1)
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("button", null, _toDisplayString(_ctx.t("streams.watch-now")), 1)
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.type == 'image' && _ctx.stream != undefined)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "stream-card",
          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.$router.push({ name: 'Stream', params: { id: _ctx.stream.id } })), ["stop"]))
        }, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("img", {
              src: _ctx.stream.thumbnailUri
            }, null, 8, _hoisted_9)
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.stream.title), 1),
            _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.stream.subTitle), 1)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}

import { defineComponent, PropType } from "vue";
import { default as CommonLabel } from "@/components/Common/Label.vue";
import { useI18n } from "vue-i18n";
import { Stream } from "@/store/state";
import { SectionColors } from "..";

export default defineComponent({
  props: {
    label: String,
    type: String,
    stream: {
      type: Object as PropType<Stream>,
      required: false,
    },
  },
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    return { t };
  },
  components: {
    CommonLabel,
  },
  data() {
    return {
      staffPick: "most\nwatched",
      mainColor: SectionColors.StreamsMainColor,
      secondaryColor: SectionColors.StreamsSecondaryColor,
    };
  },
  computed: {
    cssProps(): unknown {
      return {
        "--streams-main-color": this.$data.mainColor,
        "--streams-secondaryColor": this.$data.secondaryColor,
      }
    }
  }
});

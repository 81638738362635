import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-567bdfe3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "news-item-container" }
const _hoisted_2 = { class: "back-button-wrapper" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "back-button-wrapper" }
const _hoisted_5 = { class: "more-news" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_back = _resolveComponent("back")!
  const _component_common_section = _resolveComponent("common-section")!
  const _component_news_card = _resolveComponent("news-card")!
  const _component_layout_footer = _resolveComponent("layout-footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_back, {
        text: _ctx.t('news.back-to-news-overview'),
        location: { name: 'News' },
        class: "back-button max-width"
      }, null, 8, ["text"])
    ]),
    (_ctx.newsItem != undefined)
      ? (_openBlock(), _createElementBlock("iframe", {
          key: 0,
          ref: "news",
          scrolling: "no",
          class: "news-item max-width",
          src: _ctx.newsItem.uri
        }, null, 8, _hoisted_3))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_back, {
        text: _ctx.t('news.back-to-news-overview'),
        location: { name: 'News' },
        class: "back-button max-width"
      }, null, 8, ["text"])
    ]),
    _createVNode(_component_common_section, {
      class: "more-news-section c-section",
      colorLeft: _ctx.mainColor,
      colorRight: _ctx.secondaryColor,
      text: _ctx.t('news.more-news')
    }, null, 8, ["colorLeft", "colorRight", "text"]),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.recommendedNews, (news) => {
        return (_openBlock(), _createBlock(_component_news_card, {
          key: news.id,
          type: "row",
          news: news,
          class: "more-news-row"
        }, null, 8, ["news"]))
      }), 128))
    ]),
    (_ctx.pageReady)
      ? (_openBlock(), _createBlock(_component_layout_footer, {
          key: 1,
          spot: _ctx.adSpotFooter
        }, null, 8, ["spot"]))
      : _createCommentVNode("", true)
  ]))
}
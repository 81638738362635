
import { Options, Vue } from "vue-class-component";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faArrowRight);

@Options({
  props: {
    text: String,
    location: Object,
  },
  methods: {
    more() {
      this.$router.push(this.location);
    },
  },
  components: {
    FontAwesomeIcon,
  },
})
export default class More extends Vue {
  text!: string;
}

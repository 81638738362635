
import { defineComponent, onMounted, computed, ref } from "vue";
import {
  LayoutFooter,
  AdvertisementCard,
  ShopCard,
  Section as CommonSection,
  SectionColors,
} from "@/components";
import { useI18n } from "vue-i18n";
import { useStore, ActionTypes, AdvertisementSpots } from "@/store";

export default defineComponent({
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    const store = useStore();

    const trendingShop = computed(() => store.getters.trendingShop);
    const bestSellersShop = computed(() => store.getters.bestSellersShop);
    const staffPickShop = computed(() => store.getters.staffPickShop);
    const megaDealShop = computed(() => store.getters.megaDealShop);

    const pageReady = ref(false);

    onMounted(() =>
      Promise.all([
        store.dispatch(ActionTypes.GetTrendingShop),
        store.dispatch(ActionTypes.GetBestSellersShop),
        store.dispatch(ActionTypes.GetStaffPickShop),
        store.dispatch(ActionTypes.GetMegaDealsShop),
      ]).then(() => (pageReady.value = true))
    );

    return {
      t,
      trendingShop,
      bestSellersShop,
      staffPickShop,
      megaDealShop,
      pageReady,
    };
  },
  data() {
    return {
      adSpotTop: AdvertisementSpots.ShopTop,
      adSpotFooter: AdvertisementSpots.ShopFooter,
      mainColor: SectionColors.ShopMainColor,
      secondaryColor: SectionColors.ShopSecondaryColor,
    };
  },
  computed: {
    cssProps(): unknown {
      return {
        "--streams-main-color": this.$data.mainColor,
        "--streams-secondary-color": this.$data.secondaryColor,
      };
    },
  },
  components: {
    ShopCard,
    AdvertisementCard,
    CommonSection,
    LayoutFooter,
  },
});

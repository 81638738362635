
import { defineComponent, onMounted, computed, ref } from "vue";
import {
  LayoutFooter,
  NewsCard,
  Section as CommonSection,
  SectionColors,
  AdvertisementCard,
} from "@/components";
import { useI18n } from "vue-i18n";
import { useStore, ActionTypes, AdvertisementSpots } from "@/store";

export default defineComponent({
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    const store = useStore();

    const trendingNews = computed(() => store.getters.trendingNews);
    const latestNews = computed(() => store.getters.latestNews);
    const moreNews = computed(() => store.getters.moreNews);

    const pageReady = ref(false);

    onMounted(() =>
      Promise.all([
        store.dispatch(ActionTypes.GetTrendingNews),
        store.dispatch(ActionTypes.GetLatestNews),
        store.dispatch(ActionTypes.GetMoreNews),
      ]).then(() => (pageReady.value = true))
    );

    return {
      t,
      trendingNews,
      latestNews,
      moreNews,
      pageReady,
    };
  },
  data() {
    return {
      adSpotTop: AdvertisementSpots.NewsTop,
      adSpotFooter: AdvertisementSpots.NewsFooter,
      mainColor: SectionColors.NewsMainColor,
      secondaryColor: SectionColors.NewsSecondaryColor,
    };
  },
  computed: {
    cssProps(): unknown {
      return {
        "--news-main-color": this.$data.mainColor,
        "--news-secondary-color": this.$data.secondaryColor,
      };
    },
  },
  components: {
    LayoutFooter,
    CommonSection,
    NewsCard,
    AdvertisementCard,
  },
});

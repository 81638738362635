
import { defineComponent, ref } from "vue";
import { LayoutNavigationBar, ShopOverlay, AnnouncementOverlay } from "@/components";
import emitter from './eventEmitter';
import { initializeAnalytics } from './services/analytics';
import { initializeAdvertisements } from './services/advertisements';
import { initializeLocalization } from "./services/localization";

export default defineComponent({
  components: {
    LayoutNavigationBar,
    ShopOverlay,
    AnnouncementOverlay,
  },
  mounted() {
    emitter.on('Announcement', this.handleAnnouncement);
  },
  beforeUnmount() {
    emitter.off('Announcement', this.handleAnnouncement);
  },
  methods: {
    handleAnnouncement(payload: { active: boolean }) {
      this.isAnnouncementActive = payload.active;
      try {
        // eslint-disable-next-line
        (window as any)?.internal_imd?.announcement(payload.active);
      } catch (e) {
        // do nothing
      }
    }
  },
  setup() {
    const isAnnouncementActive = ref(false);

    initializeLocalization();
    initializeAnalytics();
    initializeAdvertisements();

    return { isAnnouncementActive };
  },
});

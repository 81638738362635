import fingerprint2 from 'fingerprintjs2';

export async function Fingerprint(): Promise<string> {
    const components = await fingerprint2.getPromise();
    const values = components.map(function (component) {
        return component.value;
    });

    const fingerprint = fingerprint2.x64hash128(values.join(""), 31);
    return fingerprint;
}
{
    "home": {
        "title": "COMMENCEZ À JOUER AVEC {company}",
        "company-name": "GLADI8TOR!"
    },
    "games": {
        "games": "Jeux",
        "play-now": "Jouer maintenant",
        "staff-pick": "Choix du personnel",
        "best-rated": "Le mieux noté",
        "more-games": "plus de jeux",
        "back-to-all-games": "retour à tous les jeux",
        "also-fun": "Également amusant"
    },
    "streams": {
        "streams": "Flux",
        "most-watched": "Le plus\nregardé",
        "watch-now": "regarder maintenant",
        "more-streams": "plus de flux",
        "back-to-all-streams": "retour à tous les flux",
        "also-fun": "Également amusant"
    },
    "news": {
        "news": "Nouvelles",
        "read-more": "En savoir plus",
        "just-in": "Juste\ndans",
        "latest-news": "dernières nouvelles",
        "more-news": "plus de nouvelles",
        "back-to-news-overview": "retour à la liste des actualités"
    },
    "advertisement": {
        "advertisement": "Publicité",
        "click-for-more-information": "cliquez pour plus d'informations",
        "back": "arrière"
    },
    "shop": {
        "shop": "Boutique",
        "shop-more": "acheter plus",
        "shop-now": "Acheter maintenant",
        "trending": "Tendance",
        "best-sellers": "Meilleures ventes",
        "staff-pick": "choix du personnel",
        "mega-deal": "méga accord",
        "back-to-shop": "retour au magasin",
        "product-description": "Description du produit",
        "pc": "PC",
        "giftcard": "Carte cadeau",
        "key": "La clé du jeu",
        "on-this-flight": "sur ce vol",
        "total": "total",
        "shopping-cart": "Panier d'achat",
        "send-purchase-request": "envoyer une demande d'achat",
        "your-email": "Votre adresse électronique",
        "agreement": "Pour continuer, saisissez votre adresse électronique et nous vous enverrons un lien pour compléter votre achat. Nous traiterons vos données personnelles conformément à la directive {privacy}.",
        "privacy-policy": "politique de confidentialité",
        "processing": "Traitement des demandes d'achat",
        "processed": "Un courriel sera envoyé pour compléter l'achat"
    }
}
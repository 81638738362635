import getEnv from '@/utils/env';
import { default as createAuth0Client, Auth0Client, User, RedirectLoginOptions, GetIdTokenClaimsOptions, GetTokenSilentlyOptions, GetTokenWithPopupOptions, LogoutOptions } from '@auth0/auth0-spa-js'
import { computed, reactive, watchEffect, ComputedRef, Plugin, App } from 'vue';
import { NavigationGuard } from 'vue-router';

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $auth: AuthPlugin
    }
}

let client: Auth0Client;

type State = {
    loading: boolean;
    isAuthenticated: boolean;
    user: User | undefined;
    popupOpen: boolean;
    error: string | null;
}

const state = reactive<State>({
    loading: true,
    isAuthenticated: false,
    user: {},
    popupOpen: false,
    error: null,
})

interface LoginWithPopupInterface {
    (): Promise<void>;
}

async function loginWithPopup() {
    state.popupOpen = true

    try {
        await client.loginWithPopup()
    } catch (e) {
        console.error(e)
    } finally {
        state.popupOpen = false
    }

    state.user = await client.getUser()
    state.isAuthenticated = true
}

interface HandleRedirectCallbackInterface {
    (): Promise<void>;
}

async function handleRedirectCallback() {
    state.loading = true

    try {
        await client.handleRedirectCallback()
        state.user = await client.getUser()
        state.isAuthenticated = true
    } catch (e) {
        state.error = e
    } finally {
        state.loading = false
    }
}

interface CallbackRedirectInterface {
     // eslint-disable-next-line
    (appState: any): void;
}

interface LoginWithRedirectInterface {
    (o: RedirectLoginOptions): void;
}

function loginWithRedirect(o: RedirectLoginOptions) {
    return client.loginWithRedirect(o)
}

interface GetIdTokenClaimsInterface {
    (o: GetIdTokenClaimsOptions): void;
}

function getIdTokenClaims(o: GetIdTokenClaimsOptions) {
    return client.getIdTokenClaims(o)
}

interface GetTokenSilentlyInterface {
    (o: GetTokenSilentlyOptions): void;
}

function getTokenSilently(o: GetTokenSilentlyOptions) {
    return client.getTokenSilently(o)
}

interface GetTokenWithPopupInterface {
    (o: GetTokenWithPopupOptions): void;
}

function getTokenWithPopup(o: GetTokenWithPopupOptions) {
    return client.getTokenWithPopup(o)
}

interface LogoutInterface {
    (o: LogoutOptions): void;
}

function logout(o: LogoutOptions) {
    return client.logout(o)
}

type AuthPlugin = {
    isAuthenticated: ComputedRef<boolean>;
    loading: ComputedRef<boolean>;
    user: ComputedRef<User | undefined>;
    getIdTokenClaims: GetIdTokenClaimsInterface;
    getTokenSilently: GetTokenSilentlyInterface;
    getTokenWithPopup: GetTokenWithPopupInterface;
    loginWithRedirect: LoginWithRedirectInterface;
    handleRedirectCallback: HandleRedirectCallbackInterface;
    loginWithPopup: LoginWithPopupInterface;
    logout: LogoutInterface;
};

const authPlugin: AuthPlugin = {
    isAuthenticated: computed(() => state.isAuthenticated),
    loading: computed(() => state.loading),
    user: computed(() => state.user),
    getIdTokenClaims,
    getTokenSilently,
    getTokenWithPopup,
    handleRedirectCallback,
    loginWithRedirect,
    loginWithPopup,
    logout,
}

export async function getBearerToken(): Promise<string | undefined> {
    if (state.isAuthenticated) {
        return `Bearer ${await client.getTokenSilently()}`;
    } 
    return undefined;
}

export async function getAccessToken(): Promise<string> {
    if (state.isAuthenticated) {
        return `?access_token=${await client.getTokenSilently()}`;
    } 
    return '';
}

export const routeGuard: NavigationGuard = (to, _, next) => {
    const oauthDomain = getEnv("VUE_APP_OAUTH_DOMAIN");
    const oauthClientID = getEnv("VUE_APP_OAUTH_CLIENT_ID");
    const oauthRedirectURI = getEnv("VUE_APP_BASE_URI") + "/callback";
    const oauthAudience = getEnv("VUE_APP_OAUTH_AUDIENCE");

    if (oauthDomain == undefined || oauthClientID == undefined || oauthRedirectURI == undefined || oauthAudience == undefined) {
        return next()
    }

    const { isAuthenticated, loading, loginWithRedirect } = authPlugin

    const verify = () => {
        // If the user is authenticated, continue with the route
        if (isAuthenticated.value) {
            return next()
        }

        // Otherwise, log in
        loginWithRedirect({ appState: { targetUrl: to.fullPath } })
    }

    // If loading has already finished, check our auth state using `fn()`
    if (!loading.value) {
        return verify()
    }

    // Watch for the loading property to change before we check isAuthenticated
    watchEffect(() => {
        if (loading.value === false) {
            return verify()
        }
    })
}

export const setupAuth = async (callbackRedirect: CallbackRedirectInterface): Promise<Plugin> => {
    const oauthDomain = getEnv("VUE_APP_OAUTH_DOMAIN");
    const oauthClientID = getEnv("VUE_APP_OAUTH_CLIENT_ID");
    const oauthRedirectURI = getEnv("VUE_APP_BASE_URI") + "/callback";
    const oauthAudience = getEnv("VUE_APP_OAUTH_AUDIENCE");

    if (oauthDomain != undefined && oauthClientID != undefined && oauthRedirectURI != undefined && oauthAudience != undefined) {
        client = await createAuth0Client({
            domain: oauthDomain,
            client_id: oauthClientID,
            redirect_uri: oauthRedirectURI,
            audience: oauthAudience,
            scope: "openid profile email read:content"
        })

        try {
            // If the user is returning to the app after authentication
            if (
                window.location.search.includes('code=') &&
                window.location.search.includes('state=')
            ) {
                // handle the redirect and retrieve tokens
                const { appState } = await client.handleRedirectCallback()

                // Notify subscribers that the redirect callback has happened, passing the appState
                // (useful for retrieving any pre-authentication state)
                callbackRedirect(appState)
            }
        } catch (e) {
            state.error = e
        } finally {
            // Initialize our internal authentication state
            state.isAuthenticated = await client.isAuthenticated()
            state.user = await client.getUser()
            state.loading = false
        }
    }

    return {
        install: (app: App) => {
            app.config.globalProperties.$auth = authPlugin
        },
    }
}
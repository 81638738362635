
import { defineComponent, ref } from "vue";
import { Back } from "@/components";
import { useI18n } from "vue-i18n";

export default defineComponent({
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    const frame = ref<HTMLIFrameElement>();
    const pageReady = ref(false);

    function handlingMsg(e: MessageEvent) {
      try {
        var messageData = JSON.parse(e.data);

        if (messageData.event == "resize") {
          if (frame.value != null) {
            frame.value.height = messageData.data.scrollHeight + "px";
            pageReady.value = true;
          }
        }
      } catch (e) {
        // Do nothing
      }
    }

    addEventListener("message", handlingMsg, true);

    return {
      t,
      frame,
    };
  },
  data() {
    return {
      uri: this.$route.params.uri as string,
    }
  },
  components: {
    Back,
  },
});

{
  "home": {
    "title": "GET IN THE GAME WITH {company}",
    "company-name": "GLADI8TOR!"
  },
  "games": {
    "games": "Games",
    "play-now": "play now",
    "staff-pick": "most\nplayed",
    "best-rated": "best rated",
    "more-games": "more games &#8230;",
    "back-to-all-games": "back to all games",
    "also-fun": "also fun"
  },
  "streams": {
    "streams": "Streams",
    "most-watched": "most\nwatched",
    "watch-now": "watch now",
    "more-streams": "more streams &#8230;",
    "back-to-all-streams": "back to all streams",
    "also-fun": "also fun"
  },
  "news": {
    "news": "News",
    "read-more": "read more",
    "just-in": "just\nin",
    "latest-news": "latest news",
    "more-news": "more news",
    "back-to-news-overview": "back to news overview"
  },
  "advertisement": {
    "click-for-more-information": "click for more information",
    "back": "back"
  },
  "shop": {
    "shop": "Shop",
    "shop-more": "shop more",
    "shop-now": "shop now",
    "trending": "Trending",
    "best-sellers": "Best sellers",
    "staff-pick": "staff pick",
    "mega-deal": "mega deal",
    "back-to-shop": "back to shop",
    "product-description": "product description",
    "pc": "PC",
    "giftcard": "Giftcard",
    "key": "Game Key",
    "on-this-flight": "On this flight",
    "total": "total",
    "shopping-cart": "Shopping Cart",
    "send-purchase-request": "send purchase request",
    "your-email": "Your email address",
    "agreement": "To continue please fill in your email address and we will send you a link to complete your purchase.\n\nWe will process your personal data in accordance with the {privacy}.",
    "privacy-policy": "privacy policy",
    "processing": "Processing purchase request",
    "processed": "An email will be sent to complete the purchase."
  }
}
<template>
  <div v-if="visible" class="announcement-overlay">
    <div class="overlay-content">
      <h1>Announcement in progress</h1>
      <p>Heads up for the inflight announcement.</p>
      <p>The show will resume shortly after.</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  }
};
</script>

<style scoped>
.announcement-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 12000;
}

.overlay-content {
  text-align: center;
  margin: 15px;
  color: black;
  padding: 20px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
}
</style>
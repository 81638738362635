
import { defineComponent, onMounted, computed, ref, watch } from "vue";
import {
  LayoutFooter,
  SectionColors,
  Back,
  Section as CommonSection,
  NewsCard,
} from "@/components";
import { useI18n } from "vue-i18n";
import { useStore, ActionTypes, AdvertisementSpots } from "@/store";
import { useRoute } from "vue-router";

export default defineComponent({
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    const news = ref<HTMLIFrameElement>();
    const pageReady = ref(false);

    const store = useStore();
    const route = useRoute();

    function handlingMsg(e: MessageEvent) {
      try {
        var messageData = JSON.parse(e.data);

        if (messageData.event == "resize") {
          if (news.value != null) {
            news.value.height = messageData.data.scrollHeight + "px";
            pageReady.value = true;
          }
        }
      } catch (e) {
        // Do nothing
      }
    }

    addEventListener("message", handlingMsg, true);

    const newsItem = computed(() =>
      store.getters.newsByID(route.params.id as string)
    );

    const recommendedNews = computed(() =>
      store.getters.recommendedNews(route.params.id as string)
    );

    const reload = async function () {
      pageReady.value = false;

      if (route.params.id == undefined) {
        return;
      }

      await store.dispatch(ActionTypes.GetNewsByID, route.params.id as string);
      await store.dispatch(
        ActionTypes.GetRecommendedNewsByID,
        route.params.id as string
      );
    };

    onMounted(() => reload());

    watch(
      () => route.params.id,
      () => reload()
    );

    return {
      t,
      newsItem,
      news,
      recommendedNews,
      pageReady,
    };
  },
  data() {
    return {
      adSpotFooter: AdvertisementSpots.NewsItemFooter,
      mainColor: SectionColors.NewsMainColor,
      secondaryColor: SectionColors.NewsSecondaryColor,
    };
  },
  components: {
    LayoutFooter,
    Back,
    CommonSection,
    NewsCard,
  },
});


import { News } from "@/store/state";
import { Label as CommonLabel } from "@/components";
import { defineComponent, PropType } from "vue";
import { SectionColors } from "@/components";
import moment from "moment";
import { faNewspaper } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useI18n } from "vue-i18n";

library.add(faNewspaper);

export default defineComponent({
  props: {
    news: {
      type: Object as PropType<News>,
      required: true,
    },
    label: String,
    type: String,
  },
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    return { t };
  },
  data() {
    return {
      mainColor: SectionColors.NewsMainColor,
      secondaryColor: SectionColors.NewsSecondaryColor,
      date: moment(this.news.publishedAt).format("LLL"),
    };
  },
  components: {
    CommonLabel,
    FontAwesomeIcon,
  },
  computed: {
    cssProps(): unknown {
      return {
        "--news-main-color": this.$data.mainColor,
        "--news-secondary-color": this.$data.secondaryColor,
      };
    },
  },
});

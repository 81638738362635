import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4da646a8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home-container" }
const _hoisted_2 = { class: "home-component-title" }
const _hoisted_3 = {
  key: 3,
  class: "more"
}
const _hoisted_4 = { class: "more more-news" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_game_card = _resolveComponent("game-card")!
  const _component_advertisement_card = _resolveComponent("advertisement-card")!
  const _component_home_section_tiles = _resolveComponent("home-section-tiles")!
  const _component_shop_card = _resolveComponent("shop-card")!
  const _component_more = _resolveComponent("more")!
  const _component_news_card = _resolveComponent("news-card")!
  const _component_layout_footer = _resolveComponent("layout-footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, [
      _createVNode(_component_i18n_t, { keypath: "home.title" }, {
        company: _withCtx(() => [
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.t("home.company-name")), 1)
        ]),
        _: 1
      })
    ]),
    (_ctx.trendingGame != undefined)
      ? (_openBlock(), _createBlock(_component_game_card, {
          key: 0,
          class: "game-card max-width",
          game: _ctx.trendingGame,
          type: "highlighted"
        }, null, 8, ["game"]))
      : _createCommentVNode("", true),
    (_ctx.pageReady)
      ? (_openBlock(), _createBlock(_component_advertisement_card, {
          key: 1,
          class: "a-card max-width",
          spot: _ctx.adSpotTop
        }, null, 8, ["spot"]))
      : _createCommentVNode("", true),
    _createVNode(_component_home_section_tiles, { class: "section-tiles max-width" }),
    (_ctx.trendingShop != undefined && _ctx.hideShop != 'true')
      ? (_openBlock(), _createBlock(_component_shop_card, {
          key: 2,
          class: "shop-card max-width",
          type: "highlighted",
          shop: _ctx.trendingShop
        }, null, 8, ["shop"]))
      : _createCommentVNode("", true),
    (_ctx.hideShop != 'true')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_more, {
            text: _ctx.t('shop.shop-more'),
            location: { name: 'Shop' },
            class: "max-width"
          }, null, 8, ["text"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.pageReady)
      ? (_openBlock(), _createBlock(_component_advertisement_card, {
          key: 4,
          class: "a-card max-width",
          spot: _ctx.adSpotMiddle
        }, null, 8, ["spot"]))
      : _createCommentVNode("", true),
    (_ctx.trendingNews != undefined)
      ? (_openBlock(), _createBlock(_component_news_card, {
          key: 5,
          class: "news-card max-width",
          type: "highlighted",
          news: _ctx.trendingNews
        }, null, 8, ["news"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_more, {
        text: _ctx.t('news.more-news'),
        location: { name: 'News' },
        class: "max-width"
      }, null, 8, ["text"])
    ]),
    (_ctx.pageReady)
      ? (_openBlock(), _createBlock(_component_layout_footer, {
          key: 6,
          spot: _ctx.adSpotFooter
        }, null, 8, ["spot"]))
      : _createCommentVNode("", true)
  ]))
}

import { Languages } from "@/i18n";
import { ActionTypes } from "@/store";
import getEnv from "@/utils/env";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBars,
  faEllipsisV,
  faShoppingCart,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  FontAwesomeIcon,
  FontAwesomeLayersText,
  FontAwesomeLayers,
} from "@fortawesome/vue-fontawesome";
import { computed, defineComponent, onMounted } from "@vue/runtime-core";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { SectionColors } from "..";

library.add(faBars, faEllipsisV, faShoppingCart, faSignOutAlt);

export default defineComponent({
  components: {
    FontAwesomeIcon,
    FontAwesomeLayers,
    FontAwesomeLayersText,
  },
  setup() {
    const store = useStore();
    const shoppingCart = computed(() => store.getters.shoppingCart);
    const apiVersion = computed(() => store.getters.version);
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });


    onMounted(() => store.dispatch(ActionTypes.GetVersion));
    onMounted(() => store.dispatch(ActionTypes.GetFlightData));

    return { shoppingCart, store, apiVersion, t };
  },
  methods: {
    exitFn() {
      if (this.exitCloseTab != undefined && this.exitCloseTab == "true") {
        window.close();
        return;
      }
      if (this.exit != undefined && this.exit != "") {
        window.location.href = this.exit;
        return;
      }
    },
    openShopOverlay() {
      this.store.dispatch(ActionTypes.OpenShopOverlay);
    },
    enableMainOverlay() {
      this.overlayMainVisible = true;
      setTimeout(() => {
        this.overlayMainTriggerAnimation = true;
      }, 20);
    },
    enableMoreOverlay() {
      this.overlayMoreVisible = true;
      setTimeout(() => {
        this.overlayMoreTriggerAnimation = true;
      }, 20);
    },
    disableMainOverlay() {
      this.overlayMainTriggerAnimation = false;

      setTimeout(() => {
        this.overlayMainVisible = false;
      }, 200);
    },
    disableMoreOverlay() {
      this.overlayMoreTriggerAnimation = false;

      setTimeout(() => {
        this.overlayMoreVisible = false;
      }, 200);
    },
    disableMainOverlayAndNavigate(pageName: string) {
      this.disableMainOverlay();
      this.$router.push({ name: pageName });
    },
    disableMoreOverlayAndSetLanguage(language: string) {
      this.disableMoreOverlay();
      this.$router.push({ params: { locale: language } });
    },
  },
  computed: {
    cssProps() {
      return {
        "--main-shop-color": SectionColors.ShopMainColor,
      };
    },
  },
  data() {
    const supportedLocales = getEnv("VUE_APP_I18N_SUPPORTED_LOCALE");
    let languages: { [key: string]: string } = {};
    if (supportedLocales != undefined) {
      supportedLocales.split(",").forEach((locale) => {
        languages[locale] = Languages[locale];
      });
    }

    return {
      overlayMainVisible: false,
      overlayMainTriggerAnimation: false,
      overlayMoreVisible: false,
      overlayMoreTriggerAnimation: false,
      languages: languages,
      exit: getEnv("VUE_APP_EXIT_ENDPOINT"),
      hideShop: getEnv("VUE_APP_DISABLE_SHOP"),
      exitCloseTab: getEnv("VUE_APP_EXIT_CLOSE_TAB"),
      window: window,
      version: getEnv("VUE_APP_VERSION"),
    };
  },
});

{
  "home": {
    "title": "ENTRA IN GIOCO CON {company}",
    "company-name": "GLADI8TOR!"
  },
  "games": {
    "games": "Giochi",
    "play-now": "Riproduci ora",
    "staff-pick": "più\ngiocato",
    "best-rated": "il più votato",
    "more-games": "più giochi &#8230;",
    "back-to-all-games": "torna a tutti i giochi",
    "also-fun": "altri giochi divertenti"
  },
  "streams": {
    "streams": "Flussi",
    "most-watched": "i più\nvisti",
    "watch-now": "Guarda ora",
    "more-streams": "più flussi &#8230;",
    "back-to-all-streams": "torna a tutti i flussi",
    "also-fun": "altri giochi divertenti"
  },
  "news": {
    "news": "Notizia",
    "read-more": "Per saperne di più",
    "just-in": "appena dentro",
    "latest-news": "ultime notizie",
    "more-news": "più notizie",
    "back-to-news-overview": "torna alla panoramica delle notizie"
  },
  "advertisement": {
    "click-for-more-information": "clicca per maggiori informazioni",
    "back": "Indietro"
  },
  "shop": {
    "shop": "Negozio",
    "shop-more": "acquista di più",
    "shop-now": "acquistare ora",
    "trending": "Tendenza",
    "best-sellers": "I più venduti",
    "staff-pick": "scelta personale",
    "mega-deal": "mega affare",
    "back-to-shop": "torna al negozio",
    "product-description": "Descrizione del prodotto",
    "pc": "PC",
    "giftcard": "Carta regalo",
    "key": "Chiave di gioco",
    "on-this-flight": "Su questo volo",
    "total": "totale",
    "shopping-cart": "Carrello della spesa",
    "send-purchase-request": "inviare richiesta di acquisto",
    "your-email": "Il tuo indirizzo di posta elettronica",
    "agreement": "Per continuare, inserisci il tuo indirizzo email e ti invieremo un link per completare l'acquisto.\n\nTratteremo i tuoi dati personali in conformità con {privacy}.",
    "privacy-policy": "l'informativa sulla privacy",
    "processing": "Elaborazione della richiesta di acquisto",
    "processed": "Verrà inviata un'e-mail per completare l'acquisto."
  }
}

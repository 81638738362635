
import { defineComponent } from "vue";
import getEnv from "../../utils/env";

export default defineComponent({
  data() {
    return {
      hideShop: getEnv("VUE_APP_DISABLE_SHOP"),
    };
  },
})


import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import i18n from "./i18n";
import { setupAuth } from "./auth";
import getEnv from "./utils/env";
import { Vue } from "vue-class-component";

// eslint-disable-next-line
(Vue.prototype as any).$pxEasyLoaded = false;

const app = createApp(App)
  .use(i18n)
  .use(store)
  .use(router);

// eslint-disable-next-line
function callbackRedirect(appState: any) {
  if (appState && appState.targetUrl) {
    const callbackUri = getEnv("VUE_APP_OAUTH_CALLBACK_URI");
    if (callbackUri != undefined) {
      router.push(
        appState && appState.targetUrl
          ? appState.targetUrl.replace(callbackUri)
          : "/"
      );
      return;
    }
  }

  router.push(appState && appState.targetUrl ? appState.targetUrl : "/");
}

setupAuth(callbackRedirect).then((auth) => {
  app.use(auth).mount("#app");
});

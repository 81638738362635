{
  "home": {
    "title": "KOMMEN SIE MIT {company} IN DAS SPIEL!",
    "company-name": "GLADI8TOR"
  },
  "games": {
    "games": "Spiele",
    "play-now": "jetzt spielen",
    "staff-pick": "meist\ngespielt",
    "best-rated": "bestbewertet",
    "more-games": "mehr Spiele &#8230;",
    "back-to-all-games": "zurück zu allen Spielen",
    "also-fun": "auch Spaß"
  },
  "streams": {
    "streams": "Streams",
    "most-watched": "meist\ngesehen",
    "watch-now": "Schau jetzt",
    "more-streams": "mehr Streams &#8230;",
    "back-to-all-streams": "zurück zu allen Streams",
    "also-fun": "auch Spaß"
  },
  "news": {
    "news": "Nachrichten",
    "read-more": "Weiterlesen",
    "just-in": "gerade\nrein",
    "latest-news": "neuesten Nachrichten",
    "more-news": "Mehr Nachrichten",
    "back-to-news-overview": "zurück zur Newsübersicht"
  },
  "advertisement": {
    "click-for-more-information": "klick für mehr informationen",
    "back": "zurück"
  },
  "shop": {
    "shop": "Einkaufen",
    "shop-more": "mehr shoppen",
    "shop-now": "Jetzt einkaufen",
    "trending": "Trending",
    "best-sellers": "Bestseller",
    "staff-pick": "Mitarbeiter pick",
    "mega-deal": "Mega-Deal",
    "back-to-shop": "zurück zum Shop",
    "product-description": "Produktbeschreibung",
    "pc": "PC",
    "giftcard": "Geschenkkarte",
    "key": "Spielschlüssel",
    "on-this-flight": "Auf diesem Flug",
    "total": "insgesamt",
    "shopping-cart": "Einkaufswagen",
    "send-purchase-request": "Kaufanfrage senden",
    "your-email": "Ihre E-Mail-Adresse",
    "agreement": "Um fortzufahren, geben Sie bitte Ihre E-Mail-Adresse ein und wir senden Ihnen einen Link zum Abschluss Ihres Kaufs.\n\nWir verarbeiten Ihre personenbezogenen Daten gemäß den {privacy}.",
    "privacy-policy": "Datenschutz-Bestimmungen",
    "processing": "Kaufanfrage bearbeiten",
    "processed": "Eine E-Mail wird gesendet, um den Kauf abzuschließen."
  }
}
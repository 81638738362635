
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  props: {
    text: String,
    colorTop: String,
    colorBottom: String,
  },
  computed: {
    cssProps(): unknown {
      return {
        "--label-color-top": this.$props.colorTop,
        "--label-color-bottom": this.$props.colorBottom,
      };
    },
  },
});

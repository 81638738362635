export * from "./Advertisement";
export * from "./Layout";
export * from "./Home";
export * from "./Game";
export * from "./Common";
export * from "./News";
export * from "./Shop";
export * from "./Stream";

export enum SectionColors {
  // News
  NewsMainColor = "#243773",
  NewsSecondaryColor = "#27a9e0",

  // Games
  GamesMainColor = "#271049",
  GamesSecondaryColor = "#9b2064",

  // Streams
  StreamsMainColor = "#e82429",
  StreamsSecondaryColor = "#f89b1e",

  // Shop
  ShopMainColor = "#3eb54a",
  ShopSecondaryColor = "#f2eb33",
}

import getEnv from "../utils/env";
import { useRouter } from "vue-router";

export function initializeAdvertisements(): void {
    const router = useRouter();

    const pxEasyEndpoint = getEnv("VUE_APP_PXEASY_ENDPOINT");
    if (pxEasyEndpoint != undefined && pxEasyEndpoint.length > 0) {
      // Check if px client external script exists, otherwise, add to head
      const pxClientScript = document.getElementById("pxClientScript");
      if (pxClientScript == null) {
        const baseURI = getEnv("VUE_APP_BASE_URI");
        const apiURI = getEnv("VUE_APP_API_ENDPOINT");

        const profilerScript = document.createElement("script");
        profilerScript.innerText = `var PXEasy_IfeLayer=window.PXEasy_IfeLayer={device:function(a){a(480>screen.width?"mobile":"desktop")},isValid(a,b){b(!0)},profile:{language:function(a){a(window.location.toString().replace("${baseURI}","").substring(1,3))},origin:function(a){fetch("${apiURI}/flightData").then(async b=>{if(b.ok&&200==b.status){var c=JSON.parse(await b.text());a(c.origin)}else{a("")}}).catch(()=>{a("")})},destination:function(a){fetch("${apiURI}/flightData").then(async b=>{if(b.ok&&200==b.status){var c=JSON.parse(await b.text());a(c.destination)}else{a("")}}).catch(()=>{a("")})}}};`;
        document.head.appendChild(profilerScript);

        const externalScript = document.createElement("script");
        externalScript.setAttribute("id", "pxClientScript");
        externalScript.setAttribute(
          "src",
          `${getEnv("VUE_APP_PXEASY_ENDPOINT")}/pxeasy/client/html5`
        );

        document.head.appendChild(externalScript);
      }
    }

    const imdEndpoint = getEnv("VUE_APP_IMD_ENDPOINT");
    if (imdEndpoint != undefined && imdEndpoint.length > 0) {
      const pxClientScript = document.getElementById("imdClientScript");
      if (pxClientScript == null) {

        router.beforeResolve((to) => {
          // eslint-disable-next-line
          (window as any).imd = {
            language: function () {
              return to.params.locale as string;
            }
          }
        });

        const externalScript = document.createElement("script");
        externalScript.setAttribute("defer", "defer")
        externalScript.setAttribute("type", "module")
        externalScript.setAttribute("id", "imdClientScript");
        externalScript.setAttribute(
          "src",
          `${getEnv("VUE_APP_IMD_ENDPOINT")}`
        );

        document.head.appendChild(externalScript);
      }
    }
}
import { createI18n } from "vue-i18n";

import en from "./locales/en.json";
import fr from "./locales/fr.json";
import de from "./locales/de.json";
import it from "./locales/it.json";
import cz from "./locales/cz.json";
import es from "./locales/es.json";

import getEnv from "./utils/env";

export const Languages: { [key: string]: string } = {
  en: "English",
  de: "Deutsch",
  it: "Italiano",
  cz: "Čeština",
  es: "Español",
  fr: "Français",
};

export default createI18n({
  legacy: false,
  locale: getEnv("VUE_APP_I18N_LOCALE") || "en",
  fallbackLocale: getEnv("VUE_APP_I18N_FALLBACK_LOCALE") || "en",
  messages: {
    en,
    de,
    it,
    cz,
    es,
    fr,
  },
});

import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-51650700"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "video-card"
}
const _hoisted_2 = { class: "image" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.advertisement != undefined)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("video", {
            autoplay: "",
            src: _ctx.advertisement.uri,
            loop: "true"
          }, null, 8, _hoisted_3)
        ])
      ]))
    : _createCommentVNode("", true)
}

import { defineComponent, PropType } from "vue";
import { Shop } from "@/store/state";
import { default as CommonLabel } from "../Common/Label.vue";
import { SectionColors } from "..";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faDesktop,
  faCartPlus,
  faGift,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useStore } from "vuex";
import { ActionTypes } from "@/store";
import { useI18n } from "vue-i18n";

library.add(faDesktop, faCartPlus, faGift);

export default defineComponent({
  props: {
    shop: {
      type: undefined as unknown as PropType<Shop>,
      required: true,
    },
    type: String,
    label: String,
  },
  components: {
    FontAwesomeIcon,
    CommonLabel,
  },
  setup() {
    const store = useStore();
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    return { t, store };
  },
  methods: {
    addToCart() {
      this.store.dispatch(ActionTypes.AddToCart, this.shop);
    },
  },
  data() {
    return {
      mainColor: SectionColors.ShopMainColor,
      secondaryColor: SectionColors.ShopSecondaryColor,
    };
  },
  computed: {
    cssProps(): unknown {
      return {
        "--main-color": this.mainColor,
        "--secondary-color": this.secondaryColor,
      };
    },
  },
});

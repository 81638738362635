import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f0a74b62"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "frame-item-container" }
const _hoisted_2 = { class: "back-button-wrapper" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "back-button-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_back = _resolveComponent("back")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_back, {
        text: _ctx.t('advertisement.back'),
        class: "back-button max-width"
      }, null, 8, ["text"])
    ]),
    _createElementVNode("iframe", {
      ref: "frame",
      scrolling: "no",
      class: "frame-item max-width",
      src: _ctx.uri
    }, null, 8, _hoisted_3),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_back, {
        text: _ctx.t('advertisement.back'),
        class: "back-button max-width"
      }, null, 8, ["text"])
    ])
  ]))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f9df5a88"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "image" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "description" }
const _hoisted_5 = { class: "slider-image" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "slider-description" }
const _hoisted_8 = { class: "image-image" }
const _hoisted_9 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_common_label = _resolveComponent("common-label")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(_ctx.cssProps)
  }, [
    (_ctx.type == 'highlighted' && _ctx.game != undefined)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "game-card",
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$router.push({ name: 'Game', params: { id: _ctx.game.id } })), ["stop"]))
        }, [
          (_ctx.label != undefined)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_common_label, {
                  colorTop: _ctx.mainColor,
                  colorBottom: _ctx.secondaryColor,
                  text: _ctx.label,
                  class: "label"
                }, null, 8, ["colorTop", "colorBottom", "text"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("img", {
              src: _ctx.game.thumbnail.landscape
            }, null, 8, _hoisted_3)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("p", null, _toDisplayString(_ctx.game.description), 1),
            _createElementVNode("button", null, _toDisplayString(_ctx.t("games.play-now")), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.type == 'slider' && _ctx.game != undefined)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "game-card",
          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.$router.push({ name: 'Game', params: { id: _ctx.game.id } })), ["stop"]))
        }, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("img", {
              src: _ctx.game.thumbnail.portrait
            }, null, 8, _hoisted_6)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("p", null, _toDisplayString(_ctx.game.category), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.type == 'image' && _ctx.game != undefined)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: "game-card",
          onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.$router.push({ name: 'Game', params: { id: _ctx.game.id } })), ["stop"]))
        }, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("img", {
              src: _ctx.game.thumbnail.portrait
            }, null, 8, _hoisted_9)
          ])
        ]))
      : _createCommentVNode("", true)
  ], 4))
}
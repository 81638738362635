import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6aa125af"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "label-wrapper",
    style: _normalizeStyle(_ctx.cssProps)
  }, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.text), 1)
  ], 4))
}

import { defineComponent } from "vue";

export default defineComponent({
  props: {
    text: String,
    colorLeft: String,
    colorRight: String,
  },
  computed: {
    cssProps(): unknown {
      return {
        "--section-color-left": this.$props.colorLeft,
        "--section-color-right": this.$props.colorRight,
      };
    },
  },
});


import { defineComponent, onMounted, computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import {
  LayoutFooter,
  AdvertisementCard,
  Section as CommonSection,
  GameCard,
  SectionColors,
} from "@/components";
import { useStore, ActionTypes, AdvertisementSpots } from "@/store";

export default defineComponent({
  data() {
    return {
      adSpotTop: AdvertisementSpots.GamesTop,
      adSpotMiddle: AdvertisementSpots.GamesMiddle,
      adSpotFooter: AdvertisementSpots.GamesFooter,
      mainColor: SectionColors.GamesMainColor,
      secondaryColor: SectionColors.GamesSecondaryColor,
    };
  },
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    const store = useStore();

    const trendingGame = computed(() => store.getters.trendingGame);
    const bestRatedGames = computed(() => store.getters.bestRatedGames);
    const gamesByCategoryFirst = computed(() => {
      let categories = store.getters.gamesByCategory;
      if (categories != null) {
        const firstHalf = Math.floor(categories?.length / 2);
        return categories.slice(0, firstHalf);
      } else {
        return null;
      }
    });
    const gamesByCategorySecond = computed(() => {
      let categories = store.getters.gamesByCategory;
      if (categories != null) {
        const firstHalf = Math.floor(categories?.length / 2);
        return categories.slice(firstHalf);
      } else {
        return null;
      }
    });

    const bundledGames = computed(() => {
      const games = store.getters.games;
      let bundledGames = [];

      if (games == undefined || games.length == 0) {
        return undefined;
      }

      for (let i = 0; i < games?.length / 8; i++) {
        const gamesSlice = games.slice(i + i * 8, i + i * 8 + 8);
        if (gamesSlice.length > 0) {
          bundledGames.push(gamesSlice);
        }
      }

      return bundledGames;
    });

    let pageReady = ref(false);

    onMounted(() =>
      Promise.all([
        store.dispatch(ActionTypes.GetTrendingGame),
        store.dispatch(ActionTypes.GetGamesByCategory),
        store.dispatch(ActionTypes.GetBestRatedGames),
        store.dispatch(ActionTypes.GetGames),
      ]).then(() => (pageReady.value = true))
    );

    return {
      t,
      trendingGame,
      bestRatedGames,
      bundledGames,
      pageReady,
      gamesByCategoryFirst,
      gamesByCategorySecond,
    };
  },
  components: {
    GameCard,
    CommonSection,
    AdvertisementCard,
    LayoutFooter,
  },
  computed: {
    cssProps(): unknown {
      return {
        "--games-main-color": this.$data.mainColor,
        "--games-secondary-color": this.$data.secondaryColor,
      };
    },
  },
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "router-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_announcement_overlay = _resolveComponent("announcement-overlay")!
  const _component_shop_overlay = _resolveComponent("shop-overlay")!
  const _component_layout_navigation_bar = _resolveComponent("layout-navigation-bar")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ 'no-interaction': _ctx.isAnnouncementActive })
  }, [
    _createVNode(_component_announcement_overlay, { visible: _ctx.isAnnouncementActive }, null, 8, ["visible"]),
    _createVNode(_component_shop_overlay, {
      class: _normalizeClass({ 'blur-content': _ctx.isAnnouncementActive })
    }, null, 8, ["class"]),
    _createVNode(_component_layout_navigation_bar, {
      class: _normalizeClass({ 'blur-content': _ctx.isAnnouncementActive })
    }, null, 8, ["class"]),
    _createElementVNode("div", {
      class: _normalizeClass(["body-wrapper", { 'blur-content': _ctx.isAnnouncementActive, 'no-interaction': _ctx.isAnnouncementActive }])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_router_view)
      ])
    ], 2)
  ], 2))
}

import { defineComponent, onMounted, computed, ref } from "vue";
import {
  LayoutFooter,
  AdvertisementCard,
  StreamCard,
  Section as CommonSection,
  SectionColors,
} from "@/components";
import { useI18n } from "vue-i18n";
import { useStore, ActionTypes, AdvertisementSpots } from "@/store";

export default defineComponent({
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    const store = useStore();

    const trendingStream = computed(() => store.getters.trendingStream);
    const latestStreams = computed(() => store.getters.latestStreams);

    const pageReady = ref(false);

    onMounted(() =>
      Promise.all([
        store.dispatch(ActionTypes.GetTrendingStream),
        store.dispatch(ActionTypes.GetLatestStreams),
      ]).then(() => (pageReady.value = true))
    );

    return {
      t,
      trendingStream,
      latestStreams,
      pageReady,
    };
  },
  data() {
    return {
      adSpotTop: AdvertisementSpots.StreamsTop,
      adSpotFooter: AdvertisementSpots.StreamsFooter,
      mainColor: SectionColors.StreamsMainColor,
      secondaryColor: SectionColors.StreamsSecondaryColor,
    };
  },
  computed: {
    cssProps(): unknown {
      return {
        "--streams-main-color": this.$data.mainColor,
        "--streams-secondary-color": this.$data.secondaryColor,
      };
    },
  },
  components: {
    StreamCard,
    AdvertisementCard,
    CommonSection,
    LayoutFooter,
  },
});


import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faMinus,
  faPlus,
  faShoppingCart,
  faTimes,
  faSpinner,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { ActionTypes } from "@/store";
import { Shop } from "@/store/state";
import { SectionColors } from "..";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

library.add(faShoppingCart, faTimes, faPlus, faMinus, faSpinner, faCheckCircle);

export default defineComponent({
  components: {
    FontAwesomeIcon,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    const shopOverlay = computed(() => store.getters.shopOverlay);
    const shoppingCart = computed(() => store.getters.shoppingCart);

    router.beforeEach((to, from, next) => {
      if (
        shopOverlay.value &&
        to.name != "PrivacyPolicy" &&
        from.name != "PrivacyPolicy"
      ) {
        store.dispatch(ActionTypes.CloseShopOverlay);
        next(false);
        return;
      }

      next(true);
    });

    return { t, store, shopOverlay, shoppingCart };
  },
  data() {
    return {
      email: "",
      loading: false,
      processed: false,
      mainColor: SectionColors.ShopMainColor,
      secondaryColor: SectionColors.ShopSecondaryColor,
    };
  },
  computed: {
    emailValid(): boolean {
      if (this.email.length > 0) {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(this.email);
      }

      return false;
    },
    cssProps(): unknown {
      return {
        "--main-color": this.mainColor,
        "--secondary-color": this.secondaryColor,
      };
    },
  },
  methods: {
    openPrivacyPolicy() {
      this.$router.push({ name: "PrivacyPolicy" });
    },
    async sendPurchaseRequest() {
      if (this.email.length > 0) {
        this.loading = true;
        await this.store.dispatch(ActionTypes.SendPurchaseRequest, this.email);
        this.loading = false;
        this.processed = true;
      }
    },
    closeOverlay() {
      this.store.dispatch(ActionTypes.CloseShopOverlay);
    },
    addItemAmount(item: Shop) {
      this.store.dispatch(ActionTypes.AddToCart, item);
    },
    subtractItemAmount(item: Shop) {
      this.store.dispatch(ActionTypes.RemoveFromCart, item);
    },
  },
});


import { defineComponent, onMounted, computed, ref } from "vue";

import {
  HomeSectionTiles,
  GameCard,
  AdvertisementCard,
  More,
  LayoutFooter,
  NewsCard,
  ShopCard,
} from "@/components";

import { useI18n } from "vue-i18n";
import getEnv from "@/utils/env";
import { useStore, ActionTypes, AdvertisementSpots } from "@/store";

export default defineComponent({
  data() {
    return {
      adSpotTop: AdvertisementSpots.HomeTop,
      adSpotFooter: AdvertisementSpots.HomeFooter,
      adSpotMiddle: AdvertisementSpots.HomeMiddle,
      hideShop: getEnv("VUE_APP_DISABLE_SHOP"),
    };
  },
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    const store = useStore();

    const trendingGame = computed(() => store.getters.trendingGame);
    const trendingShop = computed(() => store.getters.trendingShop);
    const trendingNews = computed(() => store.getters.trendingNews);

    let pageReady = ref(false);

    onMounted(() =>
      Promise.all([
        store.dispatch(ActionTypes.GetTrendingGame),
        store.dispatch(ActionTypes.GetTrendingShop),
        store.dispatch(ActionTypes.GetTrendingNews),
      ]).then(() => {
        pageReady.value = true;
      })
    );

    return {
      t,
      trendingGame,
      trendingShop,
      trendingNews,
      pageReady,
    };
  },
  components: {
    GameCard,
    ShopCard,
    More,
    AdvertisementCard,
    HomeSectionTiles,
    NewsCard,
    LayoutFooter,
  },
});

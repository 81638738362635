import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3b9d5642"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "more-btn-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.more && _ctx.more(...args)))
    }, [
      _createTextVNode(_toDisplayString(_ctx.text) + " ", 1),
      _createVNode(_component_font_awesome_icon, {
        icon: ['fa', 'arrow-right'],
        size: "1x"
      })
    ])
  ]))
}
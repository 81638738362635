
import { Options, Vue } from "vue-class-component";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faArrowLeft);

@Options({
  props: {
    text: String,
    location: Object,
  },
  components: {
    FontAwesomeIcon,
  },
  methods: {
    back() {
      if (this.location == undefined) {
        this.$router.back();
      } else {
        this.$router.push(this.location);
      }
    },
  },
})
export default class Back extends Vue {}


import { defineComponent, computed, onMounted, watch, ref } from "vue";
import { useRoute } from "vue-router";
  import {
  LayoutFooter,
  StreamCard,
  StreamPlayer,
  Section as CommonSection,
  Back,
  SectionColors,
} from "@/components";
import { useI18n } from "vue-i18n";
import { useStore, ActionTypes, AdvertisementSpots } from "@/store";

export default defineComponent({
  components: {
    Back,
    CommonSection,
    StreamCard,
    StreamPlayer,
    LayoutFooter,
  },
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    const store = useStore();
    const route = useRoute();

    const currentStream = computed(() =>
      store.getters.streamByID(route.params.id as string)
    );

    const alsoFunStreams = computed(() =>
      store.getters.alsoFunStreams(route.params.id as string)
    );

    const pageReady = ref(false);

    const reload = async function () {
      pageReady.value = false;
      await store.dispatch(
        ActionTypes.GetAlsoFunStreams,
        route.params.id as string
      );
      await store.dispatch(
        ActionTypes.GetAdvertisement,
        AdvertisementSpots.StreamFooter
      );

      pageReady.value = true;
    };

    onMounted(() => store.dispatch(ActionTypes.GetStreams));
    onMounted(() => reload());

    watch(
      () => route.params.id,
      () => reload()
    );

    return { t, currentStream, alsoFunStreams, pageReady };
  },
  data() {
    return {
      adSpotFooter: AdvertisementSpots.StreamFooter,
      mainColor: SectionColors.StreamsMainColor,
      secondaryColor: SectionColors.StreamsSecondaryColor,
    };
  },
  computed: {
    cssProps(): unknown {
      return {
        "--streams-main-color": this.$data.mainColor,
        "--streams-secondary-color": this.$data.secondaryColor,
      };
    },
  },
});

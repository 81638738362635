{
  "home": {
    "title": "ZAPOJTE SE DO HRY {company}",
    "company-name": "GLADI8TOR!"
  },
  "games": {
    "games": "Hry",
    "play-now": "hrát hned",
    "staff-pick": "nejhranější",
    "best-rated": "nejlépe hodnocené",
    "more-games": "více her &#8230;",
    "back-to-all-games": "zpět na všechny hry",
    "also-fun": "také zábava"
  },
  "streams": {
    "streams": "Streamy",
    "most-watched": "nejsledovanější",
    "watch-now": "dívej se teď",
    "more-streams": "více streamů &#8230;",
    "back-to-all-streams": "zpět na všechny streamy",
    "also-fun": "také zábava"
  },
  "news": {
    "news": "Zprávy",
    "read-more": "Přečtěte si více",
    "just-in": "právě\nv",
    "latest-news": "poslední zprávy",
    "more-news": "další novinky",
    "back-to-news-overview": "zpět na přehled novinek"
  },
  "advertisement": {
    "click-for-more-information": "klikněte pro více informací",
    "back": "zadní"
  },
  "shop": {
    "shop": "Prodejna",
    "shop-more": "nakupovat více",
    "shop-now": "Nakupuj teď",
    "trending": "Trendy",
    "best-sellers": "Nejlepší prodejci",
    "staff-pick": "výběr personálu",
    "mega-deal": "mega dohoda",
    "back-to-shop": "zpět do obchodu",
    "product-description": "Popis výrobku",
    "pc": "PC",
    "giftcard": "Dárková poukázka",
    "key": "Klíč hry",
    "on-this-flight": "Na tomto letu",
    "total": "celkový",
    "shopping-cart": "Nákupní košík",
    "send-purchase-request": "zaslat požadavek na nákup",
    "your-email": "Vaše emailová adresa",
    "agreement": "Chcete -li pokračovat, vyplňte svou e -mailovou adresu a my vám zašleme odkaz na dokončení nákupu.\n\nVaše osobní údaje budeme zpracovávat v souladu se {privacy}.",
    "privacy-policy": "zásadami ochrany osobních údajů",
    "processing": "Zpracování žádosti o nákup",
    "processed": "K dokončení nákupu bude odeslán e -mail."
  }
}
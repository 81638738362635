import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0dc4248f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "image" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "description" }
const _hoisted_5 = { class: "read-more" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "image" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "date" }
const _hoisted_10 = { class: "title" }
const _hoisted_11 = { class: "description-description" }
const _hoisted_12 = { class: "description-button" }
const _hoisted_13 = { class: "read-more" }
const _hoisted_14 = { class: "row-title" }
const _hoisted_15 = { class: "row-title-icon" }
const _hoisted_16 = { class: "row-title-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_common_label = _resolveComponent("common-label")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(_ctx.cssProps)
  }, [
    (_ctx.type == 'highlighted')
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "news-card",
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$router.push({ name: 'NewsItem', params: { id: _ctx.news.id } })), ["stop"]))
        }, [
          (_ctx.label != undefined)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_common_label, {
                  colorTop: _ctx.mainColor,
                  colorBottom: _ctx.secondaryColor,
                  text: _ctx.label,
                  class: "label"
                }, null, 8, ["colorTop", "colorBottom", "text"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("img", {
              src: _ctx.news.thumbnailUri
            }, null, 8, _hoisted_3)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("p", null, _toDisplayString(_ctx.news.title), 1),
            _createElementVNode("button", _hoisted_5, _toDisplayString(_ctx.t('news.read-more')), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.type == 'description')
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "news-card",
          style: _normalizeStyle(_ctx.cssProps),
          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.$router.push({ name: 'NewsItem', params: { id: _ctx.news.id } })), ["stop"]))
        }, [
          (_ctx.label != undefined)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_common_label, {
                  colorTop: _ctx.mainColor,
                  colorBottom: _ctx.secondaryColor,
                  text: _ctx.label,
                  class: "label"
                }, null, 8, ["colorTop", "colorBottom", "text"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("img", {
              src: _ctx.news.thumbnailUri
            }, null, 8, _hoisted_8)
          ]),
          _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.date), 1),
          _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.news.title), 1),
          _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.news.description), 1),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("button", _hoisted_13, _toDisplayString(_ctx.t('news.read-more')), 1)
          ])
        ], 4))
      : _createCommentVNode("", true),
    (_ctx.type == 'row')
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: "row-card",
          onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.$router.push({ name: 'NewsItem', params: { id: _ctx.news.id } })), ["stop"]))
        }, [
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _createVNode(_component_font_awesome_icon, {
                icon: ['fa', 'newspaper'],
                size: "lg",
                class: "white"
              })
            ]),
            _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.news.title), 1)
          ])
        ]))
      : _createCommentVNode("", true)
  ], 4))
}
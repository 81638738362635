import getEnv from "../utils/env";
import io from "socket.io-client"
import emitter from '../eventEmitter';
import { useStore } from "vuex";
import { ActionTypes, PageVisitedPayload, PageOpenedPayload } from '../store';
import { PageView } from '../store/state';
import { useRouter } from "vue-router";
import lifecycle from "page-lifecycle/dist/lifecycle.es5";

let analyticsNavigatedPageId: string | undefined = undefined;
const analyticsContentIds: Map<string, string> = new Map();
const analyticsContentDone: Map<string, boolean> = new Map();

export function initializeAnalytics(): void {
    const store = useStore();
    const router = useRouter();
    
    if (getEnv("VUE_APP_PROVIDER")?.toLowerCase() == "airfi") {
        store.dispatch(ActionTypes.ResetPageViews);

        const socketConnection = io.connect("/");
        socketConnection.on('ANNOUNCEMENT_STARTED', () => {
            emitter.emit('Announcement', { active: true });
        });

        socketConnection.on('ANNOUNCEMENT_STOPPED', () => {
            emitter.emit('Announcement', { active: false });
        })

        const analyticsConnection = io.connect("/", {
            path: "/bus/ws/"
        });

        const shareAnalytics = () => {
            const pageAnalytics = store.getters.pageViews as PageView[];

            const navigatedPageEvents = pageAnalytics.filter(i => i.name == "Home" || i.name == "Games" || i.name == "Streams" || i.name == "News");
            if (navigatedPageEvents.length == 0) {
                return
            }

            const navigatedPageFirstEvent = navigatedPageEvents[0];

            const event = {
                "e": "Navigated Page",
                "provider": "gladiator",
                "src": "gladiator",
                "ua": {
                    "ua": navigator.userAgent,
                    "l10n": navigator.language,
                },
                "meta": {
                    "ps": navigatedPageEvents.map(i => {
                        return {
                            "tSOP": (i.close == undefined) ? Date.now() - navigatedPageFirstEvent.open : i.close - navigatedPageFirstEvent.open,
                            "p": "/gladiator" + (i.page == "" ? "/home" : i.page),
                            "ts": new Date(i.open).toISOString(),
                            "params": i.params
                        }
                    })
                },
                ...(analyticsNavigatedPageId !== undefined && { _id: analyticsNavigatedPageId })
            };

            if (event.meta.ps.length > 0) {
                // eslint-disable-next-line
                analyticsConnection.emit("airfilytics", event, (response: any) => {
                    const { _id } = response;
                    analyticsNavigatedPageId = _id;
                });
            }

            pageAnalytics.filter(i => i.name == "Game" || i.name == "Stream" || i.name == "NewsItem").forEach(i => {
                const tag = [];
                let ct = "";
                let id = "";
                let name = "";

                switch (i.name) {
                    case "Game":
                        tag.push("games");
                        ct = "games";
                        id = "game_" + i.params.get("id");
                        name = store.getters.gameByID(i.params.get("id") as string)?.name;
                        break;
                    case "Stream":
                        tag.push("stream");
                        ct = "streams";
                        id = "stream_" + i.params.get("id");
                        name = store.getters.streamByID(i.params.get("id") as string)?.title;
                        break;
                    case "NewsItem":
                        tag.push("news");
                        ct = "news";
                        id = "news_" + i.params.get("id");
                        name = store.getters.newsByID(i.params.get("id") as string)?.title;
                        break;
                    default:
                        return;
                }

                const pT = (i.close == undefined) ? (Date.now() - i.open) : (i.close - i.open);

                const contentId: string | undefined = analyticsContentIds.get(i.id)
                const contentDone: boolean | undefined = analyticsContentDone.get(i.id)

                if (contentDone !== undefined && contentDone) {
                    return
                }

                const contentEvent = {
                    ...(analyticsNavigatedPageId !== undefined && { _id: contentId }),
                    "e": "content",
                    "src": "gladiator",
                    "meta": {
                        "c": {
                            "id": id,
                            "t": name,
                            "tg": tag,
                            "ct": ct,
                            "p": "gladiator",
                            "pS": {
                                "pT": pT
                            }
                        }
                    }
                }

                // eslint-disable-next-line
                analyticsConnection.emit("airfilytics", contentEvent, (response: any) => {
                    const { _id } = response;
                    analyticsContentIds.set(i.id, _id);
                });
                if (i.close !== undefined) {
                    analyticsContentDone.set(i.id, true)
                }
            })
        }

        setInterval(shareAnalytics, 30000);
    }

    // Track user when he leaves the page or exits the page
    // https://stackoverflow.com/questions/6162188/javascript-browsers-window-close-send-an-ajax-request-or-run-a-script-on-win
    store.dispatch(ActionTypes.PageSessionStarted);

    // eslint-disable-next-line
    lifecycle.addEventListener("statechange", (event: any): void => {
      switch (event.newState) {
        case "active":
          store.dispatch(ActionTypes.PageSessionStarted);
          break;
        case "hidden":
          store.dispatch(ActionTypes.PageSessionEnded);
          break;
      }
    });

    router.beforeResolve((to) => {
        store.dispatch(ActionTypes.PageOpened, {
          route: to,
        } as PageOpenedPayload);
      });

    router.afterEach((to) => {
      store.dispatch(ActionTypes.PageVisited, {
        route: to,
      } as PageVisitedPayload);
    });
}
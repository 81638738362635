import { GetterTree } from "vuex";
import { State, Game, Shop, News, Advertisement, Stream, ShoppingCart, Category, FlightData, PageView} from "./state";

export enum AdvertisementSpots {
  // Home
  HomeTop = "HOME_TOP",
  HomeFooter = "HOME_FOOTER",
  HomeMiddle = "HOME_MIDDLE",

  // Games
  GamesTop = "GAMES_TOP",
  GamesMiddle = "GAMES_MIDDLE",
  GamesFooter = "GAMES_FOOTER",
  GameFooter = "GAME_FOOTER",

  // Streams
  StreamsTop = "STREAMS_TOP",
  StreamsFooter = "STREAMS_FOOTER",
  StreamVideoPreRoll = "STREAM_VIDEO_PRE_ROLL",
  StreamFooter = "STREAM_FOOTER",

  // News
  NewsTop = "NEWS_TOP",
  NewsFooter = "NEWS_FOOTER",
  NewsItemFooter = "NEWS_ITEM_FOOTER",

  // Shop
  ShopTop = "SHOP_TOP",
  ShopFooter = "SHOP_FOOTER",
  ShopItemFooter = "SHOP_ITEM_FOOTER",
}

export type Getters = {
  version(state: State): string | undefined;
  flightData(state: State): FlightData | undefined;

  // Games
  games(state: State): Game[] | undefined;
  trendingGame(state: State): Game | undefined;
  gameByID(state: State): (id: string) => Game | undefined;
  bestRatedGames(state: State): Game[] | undefined;
  alsoFunGames(state: State): (id: string) => Game[] | undefined;
  gamesByCategory(state: State): Category[] | undefined;

  // Streams
  streams(state: State): Stream[] | undefined;
  trendingStream(state: State): Stream | undefined;
  streamByID(state: State): (id: string) => Stream | undefined;
  latestStreams(state: State): Stream[] | undefined;
  alsoFunStreams(state: State): (id: string) => Stream[] | undefined;

  // Shop
  trendingShop(state: State): Shop | undefined;
  bestSellersShop(state: State): Shop[] | undefined;
  staffPickShop(state: State): Shop[] | undefined;
  megaDealShop(state: State): Shop | undefined;
  shopItemByID(state: State): (id: string) => Shop | undefined;
  shoppingCart(state: State): ShoppingCart;
  shopOverlay(state: State): boolean;

  // News
  newsByID(state: State): (id: string) => News | undefined;
  trendingNews(state: State): News | undefined;
  latestNews(state: State): News[] | undefined;
  moreNews(state: State): News[] | undefined;
  recommendedNews(state: State): (id: string) => News[] | undefined;

  // Advertisements
  advertisementBySpot(
    state: State
  ): (spot: string) => Advertisement | undefined;
  advertisementEngineBySpot(
    state: State
  ): (spot: string) => string | undefined;

  // Analytics
  sessionStart(state: State): Date | undefined;
  pageViews(state: State): PageView[];
};

export const getters: GetterTree<State, State> & Getters = {
  // Version
  version: (state) => {
    return state.version;
  },
  flightData: (state) => {
    return state.flightData;
  },

  // Games
  games: (state) => {
    return state.games;
  },
  trendingGame: (state) => {
    return state.trendingGame;
  },
  gameByID: (state) => (id: string) => {
    return state.games.find((g) => g.id === id);
  },
  bestRatedGames: (state) => {
    return state.bestRatedGames;
  },
  alsoFunGames: (state) => (id: string) => {
    return state.alsoFunGames[id];
  },
  gamesByCategory: (state) => {
    return state.gamesByCategory;
  },

  // Streams
  streams: (state) => {
    const s: Stream[] = [];

    for (const key in state.streams) {
      const stream = state.streams[key];
      if (stream == undefined) {
        continue;
      }

      s.push(stream);
    }

    return s;
  },
  trendingStream: (state) => {
    return state.trendingStream;
  },
  latestStreams: (state) => {
    return state.latestStreams;
  },
  streamByID: (state) => (id: string) => {
    return state.streams[id];
  },
  alsoFunStreams: (state) => (id: string) => {
    return state.alsoFunStreams[id];
  },

  // Shop
  trendingShop: (state) => {
    return state.trendingShop;
  },
  bestSellersShop: (state) => {
    return state.bestSellersShop;
  },
  staffPickShop: (state) => {
    return state.staffPickShop;
  },
  megaDealShop: (state) => {
    return state.megaDealShop;
  },
  shopItemByID: (state) => (id: string) => {
    return state.shopItems[id];
  },
  shoppingCart: (state) => {
    return state.shoppingCart;
  },
  shopOverlay: (state) => {
    return state.shopOverlay;
  },

  // News
  trendingNews: (state) => {
    return state.trendingNews;
  },
  latestNews: (state) => {
    return state.latestNews;
  },
  moreNews: (state) => {
    return state.moreNews;
  },
  newsByID: (state) => (id: string) => {
    return state.news[id];
  },
  recommendedNews: (state) => (id: string) => {
    return state.recommendedNews[id];
  },

  // Advertisements
  advertisementBySpot: (state) => (spot: string) => {
    return state.advertisement[spot];
  },
  advertisementEngineBySpot: (state) => (spot: string) => {
    return state.advertisementEngine[spot];
  },

  // Analytics
  sessionStart: (state) => {
    return state.sessionStart;
  },
  pageViews: (state) => {
    return state.pageViews;
  },
};

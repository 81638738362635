
import { defineComponent, PropType } from "vue";
import { AdvertisementCard } from "@/components";
import { AdvertisementSpots } from "@/store";

export default defineComponent({
  props: {
    spot: {
      type: undefined as unknown as PropType<AdvertisementSpots>,
      required: true,
    },
  },
  components: {
    AdvertisementCard,
  },
});

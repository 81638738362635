import { MutationTree } from "vuex";
import { State, Game, Shop, News, Advertisement, Stream, Category, FlightData, PageView } from "./state";

export enum MutationType {
  SetVersion = "SET_VERSION",
  SetFlightData = "SET_FLIGHT_DATA",

  // Games
  SetGames = "SET_GAMES",
  SetAlsoFunGames = "SET_ALSO_FUN_GAMES",
  SetTrendingGame = "SET_TRENDING_GAME",
  SetBestRatedGames = "SET_BEST_RATED_GAMES",
  SetGamesByCategory = "SET_GAMES_BY_CATEGORY",

  // Streams
  SetTrendingStream = "SET_TRENDING_STREAM",
  SetLatestStreams = "SET_LATEST_STREAMS",
  SetStreams = "SET_STREAMS",
  SetAlsoFunStreams = "SET_ALSO_FUN_STREAMS",

  // Shop
  SetTrendingShop = "SET_TRENDING_SHOP",
  SetBestSellersShop = "SET_BEST_SELLERS_SHOP",
  SetStaffPickShop = "SET_STAFF_PICK_SHOP",
  SetMegaDealShop = "SET_MEGA_DEAL_SHOP",
  SetShopItem = "SET_SHOP_ITEM",
  AddToCart = "ADD_TO_CART",
  RemoveFromCart = "REMOVE_FROM_CART",
  SetShopOverlay = "SET_SHOP_OVERLAY",
  AddShoppingCartItem = "ADD_SHOPPING_CART_ITEM",
  RemoveShoppingCartItem = "REMOVE_SHOPPING_CART_ITEM",
  ClearShoppingCart = "CLEAR_SHOPPING_CART",

  // News
  SetTrendingNews = "SET_TRENDING_NEWS",
  SetLatestNews = "SET_LATEST_NEWS",
  SetMoreNews = "SET_MORE_NEWS",
  SetNewsByID = "SET_NEWS_BY_ID",
  SetRecommendedNews = "SET_RECOMMENDED_NEWS",

  // Advertisements
  SetAdvertisement = "SET_ADVERTISEMENT",
  SetAdvertisementEngineBySpot = "SET_ADVERTISEMENT_ENGINE_BY_SPOT",

  // Analytics
  SetSessionStart = "SET_SESSION_START",
  SetPageOpened = "SET_PAGE_OPENED",
  SetClearPageViews = "SET_CLEAR_PAGE_VIEWS",
}

export type SetAdvertisementEngineBySpotPayload = {
  engine: string;
  spot: string;
};

export type SetAdvertisementPayload = {
  advertisement: Advertisement;
  spot: string;
};

export type SetAlsoFunGamesPayload = {
  games: Game[];
  id: string;
};

export type SetAlsoFunStreamsPayload = {
  streams: Stream[];
  id: string;
};

export type SetRecommendedNewsPayload = {
  news: News[];
  id: string;
};

export type Mutations = {
  [MutationType.SetVersion](state: State, value: string): void;
  [MutationType.SetFlightData](state: State, value: FlightData): void;

  // Games
  [MutationType.SetGames](state: State, value: Game[]): void;
  [MutationType.SetAlsoFunGames](
    state: State,
    value: SetAlsoFunGamesPayload
  ): void;
  [MutationType.SetTrendingGame](state: State, value: Game): void;
  [MutationType.SetBestRatedGames](state: State, value: Game[]): void;
  [MutationType.SetGamesByCategory](state: State, value: Category[]): void;

  // Streams
  [MutationType.SetTrendingStream](state: State, value: Stream): void;
  [MutationType.SetLatestStreams](state: State, value: Stream[]): void;
  [MutationType.SetStreams](state: State, value: Stream[]): void;
  [MutationType.SetAlsoFunStreams](
    state: State,
    value: SetAlsoFunStreamsPayload
  ): void;

  // Shop
  [MutationType.SetTrendingShop](state: State, value: Shop): void;
  [MutationType.SetBestSellersShop](state: State, value: Shop[]): void;
  [MutationType.SetStaffPickShop](state: State, value: Shop[]): void;
  [MutationType.SetMegaDealShop](state: State, value: Shop): void;
  [MutationType.SetShopItem](state: State, value: Shop): void;
  [MutationType.AddToCart](state: State, value: Shop): void;
  [MutationType.RemoveFromCart](state: State, value: Shop): void;
  [MutationType.SetShopOverlay](state: State, value: boolean): void;
  [MutationType.ClearShoppingCart](state: State, value: unknown): void;

  // News
  [MutationType.SetTrendingNews](state: State, value: News): void;
  [MutationType.SetLatestNews](state: State, value: News[]): void;
  [MutationType.SetMoreNews](state: State, value: News[]): void;
  [MutationType.SetNewsByID](state: State, value: News): void;
  [MutationType.SetRecommendedNews](
    state: State,
    value: SetRecommendedNewsPayload
  ): void;

  // Advertisements
  [MutationType.SetAdvertisement](
    state: State,
    value: SetAdvertisementPayload
  ): void;
  [MutationType.SetAdvertisementEngineBySpot](
    state: State,
    value: SetAdvertisementEngineBySpotPayload
  ): void;

  // Analytics
  [MutationType.SetSessionStart](state: State, value: Date): void;
  [MutationType.SetPageOpened](state: State, value: PageView): void;
  [MutationType.SetClearPageViews](state: State): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationType.SetVersion](state, version) {
    state.version = version;
  },
  [MutationType.SetFlightData](state, data) {
    state.flightData = data;
  },

  // Games
  [MutationType.SetGames](state, games) {
    state.games = games;
  },
  [MutationType.SetTrendingGame](state, value) {
    state.trendingGame = value;
  },
  [MutationType.SetBestRatedGames](state, value) {
    state.bestRatedGames = value;
  },
  [MutationType.SetAlsoFunGames](state, value) {
    state.alsoFunGames[value.id] = value.games;
  },
  [MutationType.SetGamesByCategory](state, value) {
    state.gamesByCategory = value;
  },

  // Streams
  [MutationType.SetTrendingStream](state, value) {
    state.trendingStream = value;
  },
  [MutationType.SetLatestStreams](state, value) {
    state.latestStreams = value;
  },
  [MutationType.SetStreams](state, value) {
    value.forEach((stream) => (state.streams[stream.id] = stream));
  },
  [MutationType.SetAlsoFunStreams](state, value) {
    state.alsoFunStreams[value.id] = value.streams;
  },

  // Shop
  [MutationType.SetTrendingShop](state, value) {
    state.trendingShop = value;
  },
  [MutationType.SetBestSellersShop](state, value) {
    state.bestSellersShop = value;
  },
  [MutationType.SetStaffPickShop](state, value) {
    state.staffPickShop = value;
  },
  [MutationType.SetMegaDealShop](state, value) {
    state.megaDealShop = value;
  },
  [MutationType.SetShopItem](state, value) {
    state.shopItems[value.id] = value;
  },
  [MutationType.AddToCart](state, value) {
    const item = state.shoppingCart.items.find(i => i.item.id == value.id)
    if (item == undefined) {
      state.shoppingCart.items.push({
        item: value,
        amount: 1,
        price: value.price,
      })
    } else {
      const amount = item.amount + 1;
      state.shoppingCart.items.splice(state.shoppingCart.items.indexOf(item), 1, {
        amount: amount,
        item: item.item,
        price: value.price * amount,
      })
    }

    recalculateShoppingCartTotals(state);

    localStorage.setItem("shopping-cart", JSON.stringify(state.shoppingCart))
  },
  [MutationType.RemoveFromCart](state, value) {
    const item = state.shoppingCart.items.find(i => i.item.id == value.id)
    if (item == undefined) {
      return;
    } else {
      const amount = item.amount - 1;

      if (amount < 1) {
        state.shoppingCart.items.splice(state.shoppingCart.items.indexOf(item), 1)
      } else {
        state.shoppingCart.items.splice(state.shoppingCart.items.indexOf(item), 1, {
          amount: amount,
          item: item.item,
          price: value.price * amount,
        })
      }
    }

    recalculateShoppingCartTotals(state);
    localStorage.setItem("shopping-cart", JSON.stringify(state.shoppingCart))
  },
  [MutationType.SetShopOverlay](state, value) {
    state.shopOverlay = value;
  },
  [MutationType.ClearShoppingCart](state) {
    state.shoppingCart = {
      itemCount: 0,
      items: [],
      price: 0,
    };
    
    localStorage.setItem("shopping-cart", JSON.stringify(state.shoppingCart));
  },

  // News
  [MutationType.SetTrendingNews](state, value) {
    state.trendingNews = value;
  },
  [MutationType.SetLatestNews](state, value) {
    state.latestNews = value;
  },
  [MutationType.SetMoreNews](state, value) {
    state.moreNews = value;
  },
  [MutationType.SetNewsByID](state, value) {
    state.news[value.id] = value;
  },
  [MutationType.SetRecommendedNews](state, value) {
    state.recommendedNews[value.id] = value.news;
  },

  // Advertisement
  [MutationType.SetAdvertisement](state, value) {
    state.advertisement[value.spot] = value.advertisement;
  },
  [MutationType.SetAdvertisementEngineBySpot](state, value) {
    state.advertisementEngine[value.spot] = value.engine;
  },

  // Analytics
  [MutationType.SetSessionStart](state, value) {
    state.sessionStart = value;
  },
  [MutationType.SetPageOpened](state, value) {
    if (state.pageViews.length > 0) {
      state.pageViews[state.pageViews.length - 1].close = Date.now();
    }
    state.pageViews.push(value);
  },
  [MutationType.SetClearPageViews](state) {
    state.pageViews = [];
  },
};

function recalculateShoppingCartTotals(state: State) {
  let totalPrice = 0;
  let itemCount = 0;

  state.shoppingCart.items.forEach(i => {
    totalPrice += i.price;
    itemCount += i.amount;
  });

  state.shoppingCart.price = totalPrice;
  state.shoppingCart.itemCount = itemCount;

  if (itemCount < 1) {
    state.shopOverlay = false;
  }
}
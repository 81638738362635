
import { ActionTypes, AdvertisementSpots } from "@/store";
import Hls from "hls.js";
import { computed, defineComponent, onMounted, ref, watchEffect } from "vue";
import { useStore } from "vuex";
import emitter from "@/eventEmitter";

export default defineComponent({
  props: {
    src: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      screenWidth: Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      ),
    };
  },
  computed: {
    // eslint-disable-next-line
    playerWidth: (data: any) => {
      return data.screenWidth >= 830
        ? "800"
        : (data.screenWidth - 30).toString();
    },
    // eslint-disable-next-line
    playerHeight: (data: any) => {
      return data.screenWidth >= 830
        ? 800 / (16 / 9)
        : ((data.screenWidth - 30) / (16 / 9)).toString();
    },
  },
  methods: {
    handleAnnouncement(payload: { active: boolean }) {
      if (payload.active) {
        try {
          // eslint-disable-next-line
          const d: any = document;
          if (d.exitFullscreen) {
            d.exitFullscreen();
          } else if (d.webkitExitFullscreen) {
            d.webkitExitFullscreen();
          } else if (d.msExitFullscreen) {
            d.msExitFullscreen();
          }

          (document.getElementById("vpl") as HTMLVideoElement).pause();
        } catch (e) {
          // do nothing
        }
      }
    },
    resize() {
      this.screenWidth = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      );
    },
  },
  beforeUnmount() {
    emitter.off('Announcement', this.handleAnnouncement);

    const vastPlayer = document.getElementsByClassName('vastPlayerDiv')
    if (vastPlayer.length > 0) {
      const videoPlayers = vastPlayer[0].getElementsByTagName('video');
      if (videoPlayers.length > 0) {
        videoPlayers[0].pause()
        videoPlayers[0].removeAttribute('src');
        videoPlayers[0].load();
      }
    }
  },
  unmounted() {
    window.removeEventListener("resize", this.resize);
  },
  mounted() {
    emitter.on('Announcement', this.handleAnnouncement);
    window.addEventListener("resize", this.resize);
  },
  setup(props) {
    const player = ref<HTMLVideoElement>();
    const playerWrapper = ref<HTMLDivElement>();

    let hls = new Hls();
    const store = useStore();

    const adEngine = computed(() =>
      store.getters.advertisementEngineBySpot(
        AdvertisementSpots.StreamVideoPreRoll
      )
    );

    let callback = "";

    // eslint-disable-next-line
    if ((window as any).imd == undefined) {
      // eslint-disable-next-line
      (window as any).imd = {}
    }

    // eslint-disable-next-line
    (window as any).imd.videoCallback = function() {
      (document.getElementById("vpl") as HTMLVideoElement).play()
    }

    watchEffect(() => {
      if (player.value != undefined && adEngine.value != undefined) {
        if (adEngine.value == "imm") {
          const random = (length = 8) => {
            return Math.random().toString(16).substr(2, length);
          };

          const uuid = random();
          // eslint-disable-next-line
          (window as any)["pxVideoCallback" + uuid] = () => {
            if (player.value != undefined) {
              player.value?.play()
            }
          };

          callback = "window.pxVideoCallback" + uuid;
        }

        if (player.value.canPlayType("application/vnd.apple.mpegURL") !== "") {
          player.value.setAttribute("src", props.src);

          if (adEngine.value == "gladi8tor") {
            player.value.play();
          }
        }

        hls.loadSource(props.src);
        hls.attachMedia(player.value);

        if (adEngine.value == "imm") {
          // eslint-disable-next-line
          (window as any).PXEasy.build(playerWrapper.value);
        }
      }
    });

    onMounted(() => {
      store.dispatch(
        ActionTypes.GetAdvertisementEngineBySpot,
        AdvertisementSpots.StreamVideoPreRoll
      );
    });

    return { player, adEngine, callback, playerWrapper };
  },
});

{
  "home": {
      "title": " EMPIEZA A JUGAR CON {company}",
      "company-name": "GLADI8TOR!"
  },
  "games": {
      "games": "Juegos",
      "play-now": " Empieza\na jugar",
      "staff-pick": "Más jugado",
      "best-rated": "Mejor valorada",
      "more-games": "más juegos",
      "back-to-all-games": "volver a todos los juegos",
      "also-fun": "También divertida"
  },
  "streams": {
      "streams": "Canales",
      "most-watched": "Más\nvistos",
      "watch-now": "ver ahora",
      "more-streams": "más canale",
      "back-to-all-streams": "volver a los canales",
      "also-fun": "También divertida "
  },
  "news": {
      "news": "Noticias",
      "read-more": "Lee mas",
      "just-in": "Acaba\nde Llegar",
      "latest-news": "últimas noticias",
      "more-news": "mas noticias",
      "back-to-news-overview": "volver a las noticias"
  },
  "advertisement": {
      "click-for-more-information": "Haz click aquí para más información",
      "back": "atrás"
  },
  "shop": {
      "shop": "Tienda",
      "shop-more": "compre más",
      "shop-now": "Compra ahora",
      "trending": "Tendencias",
      "best-sellers": "Los más vendidos ",
      "staff-pick": "selección recomendada",
      "mega-deal": "mega oferta",
      "back-to-shop": "volver a la tienda",
      "product-description": "Descripción del Producto",
      "pc": "PC",
      "giftcard": "Tarjeta regalo",
      "key": "Clave del juego",
      "on-this-flight": "en este vuelo",
      "total": "total",
      "shopping-cart": "Carrito de la compra",
      "send-purchase-request": "enviar solicitud de compra",
      "your-email": "Su dirección de correo electrónico",
      "agreement": "Para continuar, complete su dirección de correo electrónico y le enviaremos un enlace para completar su compra. Procesaremos sus datos personales de acuerdo con la {privacy}.",
      "privacy-policy": "política de privacidad",
      "processing": "Procesando solicitud de compra",
      "processed": "Se enviará un correo electrónico para completar la compra."
  }
}